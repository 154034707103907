::v-deep .el-table th {
  padding: 0px ;
}
::v-deep .el-table thead {
  height: 15px !important;
  color: #474646;
  font-size: 12px;
}
::v-deep .el-table td {
  padding: 3px ;
  font-size: 11px;
}
.item-width {
  width: 48%;
  color: #409EFF;
}
.item-width2 {
  width: 97%;
  color: #409EFF;
}
.top {
  padding: 10px;
  background: rgba(0, 153, 229);
  color: #fff;
  text-align: center;
  font-size: 10px;
  border-radius: 2px;
}
.pub_dialog {
  display: flex;
  justify-content: center;
  align-items: Center;
  overflow: hidden;
}
.pub_dialog .el-dialog {
  margin: 0 auto !important;
  height: 90%;
  overflow: hidden;
}
.pub_dialog .el-dialog .el-dialog__body {
  position: absolute;
  left: 0;
  top: 54px;
  bottom: 0;
  right: 0;
  padding: 0;
  z-index: 1;
  overflow: hidden;
  overflow-y: auto;
}
